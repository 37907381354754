export const Common = {
	'Loading...': 'Cargando...',
	'We are saving your information...': 'Estamos guardando tu información...',
	'Current session is expired':
		'Tu sesión ha caducado. Por favor, ingresa nuevamente.',
	Courses: 'Cursos',
	Certificates: 'Certificados',
	'Frequent questions': 'Preguntas frecuentes',
	'My profile': 'Mi perfil',
	Logout: 'Cerrar sesión',
	'My courses': 'Mis cursos',
	'Start Course': 'Ir al curso',
	'Download certificate': 'Descargar certificado',
	'Downloading...': 'Descargando...',
	'My certificates': 'Mis certificados',
	Details: 'Detalles',
	Names: 'Nombres',
	Lastname: 'Apellidos',
	Email: 'Correo electronico',
	'Save changes': 'Guardar cambios',
	'Saving...': 'Guardando...',
	'You do not have any certificate yet':
		'Aún no tienes ningún certificado, ¡sigue estudiando!',
	Back: 'Atras',
	Next: 'Siguiente',
	'Skip recommendations': 'Saltar recomendaciones',
	// Step 1
	'Previous recommendations to validate your identity':
		'Recomendaciones previas para validar tu identidad',
	'To complete this process we need:':
		'Para completar este proceso necesitamos:',
	'Have your identity card handy': 'Ten a mano tu cédula de identidad',
	'Find a place with good illumination':
		'Encuentra un lugar con buena iluminación',
	'The following steps are intended to facilitate registration and ensure that the delivery of your courses is transparent and of quality.':
		'Los siguientes pasos están destinados a facilitar el registro y asegurar que la entrega de sus cursos sea transparente y de calidad.',
	'We assure you that the photos you give us will be':
		'Te aseguramos que las fotos que nos des serán',
	'completely confidential.': 'completamente confidencial.',
	'For more information on the use of data and photos, you can review the privacy policy.':
		'Para más información sobre el uso de datos y fotos, puede revisar la política de privacidad.',
	'Allows Webcam access': 'Permite el acceso a la cámara web',
	'Your browser could ask you to enable your webcam. If you see a message like this please click on "Allow"':
		'Su navegador podría pedirle que habilite su cámara web. Si ve un mensaje como este, haga clic en "Permitir"',
	'How to take a good photo of your DNI?':
		'¿Cómo hacer una buena foto de tu DNI?',
	'Align the corners just in the guideline intersection':
		'Alinear las esquinas justo en la intersección de la guía',
	'It is important that your identity card is not bent or uneven. If it is easier for you, affirm it with both hands.':
		'Es importante que su documento de identidad no esté doblado o desnivelado. Si te resulta más fácil, acéptalo con ambas manos.',
	'Make sure that the computer screen reflects as little as possible on your ID. If you can lower the brightness of the screen (look for the key with the sun).':
		'Asegúrese de que la pantalla de la computadora refleje lo menos posible en su identificación. Si puedes bajar el brillo de la pantalla (busca la tecla con el sol).',
	'Avoid those common mistakes': 'Evita esos errores comunes',
	'How to take a good photo of your face?':
		'¿Cómo tomar una buena foto de tu cara?',
	'Position your face right at the intersection of the guide lines':
		'Coloca tu cara justo en la intersección de las líneas guía',
	'Focus your full face and looking straight ahead.':
		'Enfoca tu cara completa y mira al frente.',
	'Make sure your facial features stand out.':
		'Asegúrate de que tus rasgos faciales se destaquen',
	'Remember that you should take in a place with good lighting.':
		'Recuerda que debes tomar en un lugar con buena iluminación.',
	'How to get better photos?': '¿Cómo obtener mejores fotos?',
	'Avoid those mistakes': 'Evitar esos errores',
	'A lot of light': 'Mucha luz',
	'A lot of shadow or backlight': 'Mucha sombra o luz de fondo',
	'Remember that you can also complete the registration from your cell phone, you will have better quality photos.':
		'Recuerda que también puedes completar el registro desde tu celular, tendrás fotos de mejor calidad.',
	'Done!': '¡Hecho!',
	'You can now start taking your photos and validate your identity.':
		'Ahora puede comenzar a tomar sus fotos y validar su identidad.',
	'Open PDF': 'Abrir PDF',
	'You do not have courses yet': 'Aun no tienes cursos',
	'Load courses': 'Actualizar Carga Académica',
};
